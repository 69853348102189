* {
  box-sizing: border-box;
}

body {
  margin: 0;
  background-color: lightgray;
}

.container {
  padding: 1.5rem;
  max-width: 1200px;
  min-height: calc(100vh - 48px);
}

.map-container {
  width: 95%;
  height: 50vh;
  border-radius: 5px;
}

.sansita {
  font-family: 'Sansita Swashed', cursive;
}

.roboto {
  font-family: Roboto, sans-serif;
}

.bg-img {
  background-image: url('./images/sky.jpg');
  background-size: cover;
  background-position-x: center;
  background-position-y: center;
  overflow: hidden;
  height: calc(100vh - 48px);
  width: 100%;
}

.truncate {
  width: calc(100% - 250px);
}

@media (width >= 640px) {
  .container {
    min-height: calc(100vh - 53px);
  }

  .bg-img {
    height: calc(100vh - 53px);
  }

  .map-container {
    height: 100%;
  }

  .truncate {
    width: calc(100% - 300px);
  }
}

.bg-gold {
  background-color: #e8aa42;
}

.bg-orange {
  background-color: #e57c23;
}

.bg-green {
  background-color: #025464;
}

.overflow-scroll::-webkit-scrollbar {
  display: none;
}

.active {
  font-weight: 500;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
}

.loader::before,
.loader::after {
  content: '';
  box-sizing: border-box;
  position: absolute;
  inset: 0;
  border-radius: 50%;
  border: 5px solid #e8aa42;
  animation: prixClipFix 2s linear infinite;
}

.loader::after {
  border-color: #e57c23;
  animation: prixClipFix 2s linear infinite, rotate 0.5s linear infinite reverse;
  inset: 6px;
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }

  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }

  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }

  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }

  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.intro {
  animation: 1.2s ease-in fadeUp;
}

.hp-login {
  animation: 1s ease-in 1s backwards fadeUp;
}

@keyframes fadeUp {
  0% {
    transform: translateY(30px);
    opacity: 0;
  }

  100% {
    transform: translateY(0);
    opacity: 1;
  }
}
